import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ApkForm from '../../components/Forms/ApkForm/ApkForm';
import Navigation, { Redirection } from '../../components/Navigation';

const UploadApk = (props) => {
    const history = useHistory();

    Redirection('superAdmin');

    const [data, setData] = useState({})

    useEffect(() => {
        /**
     * Verify the user, add in localStorage and redirect
     * 
     * @param {String} url Url of the server
     */
        async function ApkVersion(url) {
            try {
                let response = await fetch(url + '/api/appversion', {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                    },
                })
                let data = await response.json();
                let file = await data.appVersion[0];
                setData(file);
            } catch (error) {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
            }

        }

        ApkVersion(props.url);

    }, [props, history])

    return (
        <div className="boxPageAdmin">
            <Navigation />
            <div className="box3">
                <ApkForm
                    url={props.url}
                    method="PUT"
                    data={data}
                />
            </div>
        </div>
    );
};

export default UploadApk;