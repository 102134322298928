import React from 'react';
import { NavLink } from 'react-router-dom';

import { useHistory } from 'react-router';

/**
 * Component: It's the part navigation of the site, it's exclusive for admin
 * 
 * @param {*} props .classUser(It's the class for the page User, It's a String)
 * .classTrash(It's the class for the page Trash, It's a String)
 * .classMap(It's the class for the page Map, It's a String)
 * .classLog(It's the class for the page Log, It's a String)
 * @returns The navigation
 */
const Navigation = (props) => {

    function navClick() {
        var x = document.getElementById("navAdminMobilList");
        var nav = document.getElementById("iconNav01");
        var nav02 = document.getElementById("iconNav02");
        var nav03 = document.getElementById("iconNav03");
        if (x.style.top === "-1000px") {
            x.style.top = "80px";
            x.style.opacity = "1";
            x.style.zIndex = "1000";

            nav.style.transform = "translateY(12px) rotate(135deg)";
            nav02.style.transform = "scale(0)";
            nav03.style.transform = "translateY(-12px) rotate(-135deg)";

        } else {
            x.style.top = "-1000px";
            x.style.opacity = "0";
            x.style.zIndex = "0";

            nav.style.transform = "translateY(0) rotate(0)";
            nav02.style.transform = "scale(1)";
            nav03.style.transform = "translateY(0) rotate(0)";

        }
    }

    /**
     * If you are superAdmin you can access to Upload and see it on the Nav
     * 
     * @returns The NavLink of Upload
     */
    function Upload(){
        if (ParseJwt(localStorage.getItem('Token')).group === 'superAdmin') {
            return (
            <NavLink exact to="/upload">
            <div>Upload</div>
            </NavLink>
        );
        }
    }

    return (
        <>
            <nav className="navAdmin">
                <div className="navAdminList">
                    <h3>Harta deșeurilor</h3>
                    {/* The NavLink are generate by react-router-dom. 
                They are like <a href=""></a> */}
                    <NavLink exact to="admin-user">
                        <div className={props.classUser + " pictoUser"}>Users</div>
                    </NavLink>
                    <NavLink exact to="admin-trash">
                        <div className={props.classTrash + " pictoTrash"}>Trashes List</div>
                    </NavLink>
                    <NavLink exact to="admin-map">
                        <div className={props.classMap + " pictoMap"}>Map</div>
                    </NavLink>
                    <NavLink exact to="admin-log">
                        <div className={props.classLog + " pictoLogs"}>Logs</div>
                    </NavLink>
                    {Upload()}
                </div>
                <div className="navAdminExit" >
                    {/* Disconnect clear the local storage */}
                    <NavLink exact to="/" onClick={() => localStorage.clear()}>
                        <div className=" pictodisco">Log out</div>
                    </NavLink>
                </div>

            </nav>
            <nav className="navAdminMobil">
                <div onClick={() => navClick()} className="iconNav">
                    <div id="iconNav01" className="iconNav01"></div>
                    <div id="iconNav02" className="iconNav02"></div>
                    <div id="iconNav03" className="iconNav03"></div>
                </div>

                <div>
                    <NavLink exact to="/" onClick={() => localStorage.clear()}>
                        <div className=" pictodisco">Log out</div>
                    </NavLink>
                </div>

                <ul id="navAdminMobilList" className="navAdminMobilList">
                    <li><NavLink exact to="admin-user" className={props.classUser + " pictoUser"}>User</NavLink></li>
                    <li><NavLink exact to="admin-trash" className={props.classTrash + " pictoTrash"}>TrashList</NavLink></li>
                    <li><NavLink exact to="admin-map" className={props.classMap + " pictoMap"}>Map</NavLink></li>
                    <li><NavLink exact to="admin-log" className={props.classLog + " pictoLogs"}>Logs</NavLink></li>
                </ul>
            </nav>
        </>

    );
};

export default Navigation;

/**
 * Traduct a token
 * 
 * @param {String} token The token you need to traduct
 * @returns return the traduct of token
 */
const ParseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let parse = JSON.parse(jsonPayload)

    return parse;
};

/**
 * Make a redirection if he hav'nt the good Group
 * 
 * @param {String} role It's the role he must to have in the localStorage Group
 */
const Redirection = (role) => {
    let history = useHistory();
    if(role !== 'user') {
        if(!localStorage.getItem('Token')) {
            localStorage.clear();
            history.push('/');
            window.location.reload();

        } else if(ParseJwt(localStorage.getItem('Token')).group === 'superAdmin'){
            
        } else {
            if (ParseJwt(localStorage.getItem('Token')).group !== role) {
                localStorage.clear();
                history.push('/');
                window.location.reload();
            }
        }
    } else {
        if (localStorage.getItem('Group') !== role) {
            localStorage.clear();
            history.push('/');
            window.location.reload();
        }
    }
};

/**
 * Make a redirection automatic if the Group is good
 */
const StartRedirection = () => {
    let history = useHistory();
    if (ParseJwt(localStorage.getItem('Token')).group === 'admin') {
        history.push('/admin-user');
    } else if (localStorage.getItem('Group') === 'user') {
        history.push('/download');
    }
};

export { ParseJwt, Redirection, StartRedirection };
