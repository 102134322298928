import React from 'react';

const TextInput = (props) => {
    
        return (
            <div id={props.id} className={props.classdiv}>
                <label className={props.classlabel}>{props.title}</label>
                {/* for the checked, radio and checkbox use it but they are different so I change the method depending on the type */}
                <input 
                    placeholder={props.placeholder}
                    className= {props.classinput} 
                    type={props.type} 
                    value={props.value} 
                    name={props.name} 
                    onChange={props.handlechange} 
                    checked={props.checked===props.value}
                    accept={props.accept}
                />
                {props.error && <p className="error">{props.error}</p>}
            </div>
        );

   
};

export default TextInput;