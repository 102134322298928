import React from 'react';

/**
 * It's the Pagination, change the page of the data
 * 
 * @param {*} props .actualpage(It's the actual page)
 * .changepage(Change the data in page)
 * .pagemax(number max of page)
 * @returns The pagination with this format << 1 2 3 4 5 6 >>
 */
const Pagination = (props) => {

    /**
     * Show number actual and for other
     * 
     * @param {Number} page Actual page
     * @param {Number} max Max of pages
     * @param {*} change Allow to change the page
     * @returns pagination
     */
    function PaginationMiddle(page, max, change) {
        let div = <div>...</div>;

        let pagesmoins2 = <button onClick={() => {change(page - 2); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'}) }}>{page - 2}</button>;

        let pagesmoins1 = <button onClick={() => {change(page - 1); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'}) }}>{page - 1}</button>;

        let pages = <button className='btprinc'>{page}</button>;

        let pagesplus1 = <button onClick={() => {change(page + 1); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'}) }}>{page + 1}</button>;

        let pagesplus2 = <button onClick={() => {change(page + 2); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'}) }}>{page + 2}</button>;

        return (
            <>
                {page > 3 ? div : null}
                {page > 2 ? pagesmoins2 : null}
                {page > 1 ? pagesmoins1 : null}
                {pages}
                {page < max ? pagesplus1 : null}
                {page < max - 1 ? pagesplus2 : null}
                {page < max - 2 ? div : null}
            </>
        )
    }


    return (
        <>
            <div>
                {/* Come Back to 1 */}
                <button className="button" onClick={() => {props.changepage(1); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'})}}>&lt;&lt;</button>
                {PaginationMiddle(props.actualpage, props.pagemax, props.changepage)}
                {/* Go to the end */}
                <button className="button" onClick={() => {props.changepage(props.pagemax); document.querySelector(".boxTable").scrollTo({top: 0, behavior: 'smooth'})}}>&gt;&gt;</button>
            </div>
            <div>
                {props.actualpage}
            </div>
        </>
    );
};

export default Pagination;