
const ValidationForm = (values) => {
    // define the error and the message you want return
    let errors={
    };

    //Collect all the key data
    const column = values && Object.keys(values);

    //For the key choose the good case, because if we return all the case for many form we don't need the password2 and it is return so it's a problem
    for (let i = 0; i < column.length; i++) {
        switch (column[i]) {
            case 'firstname':
                if (!values.firstname) {
                    errors.firstname = "First Name is required";
                }
                break;
            case 'lastname':
                if (!values.lastname) {
                    errors.lastname = "Last Name is required";
                }
                break;
            case 'email':
                if (!values.email) {
                    errors.email="Email is required";
                } else  if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
                    errors.email = "Email address is invalid";
                }
                break;
            case 'password':
                if (!values.password) {
                    errors.password = "Password is required";
                } else if(values.password.length < 5){
                    errors.password = "Password must be more than five characters";
                }
                break;
            case 'password2':
                if(!values.password2){
                    errors.password2 = "Password is required";
                } else if(values.password2 !== values.password){
                    errors.password2 = "Passwords do not match";
                }
                break;
            case 'location':
                if (values.location === '') {
                    errors.location = "You need to choose a location";
                }
                break;
            case 'apk':
                if (!values.apk) {
                    errors.apk = "You need to enter the file of the apk";
                }
                break;
            case 'versionNumber':
                if (!values.versionNumber) {
                    errors.versionNumber = "You need to enter the version of the apk";
                } else if(!/^[0-9]+\.[0-9]{1,}$/i.test(values.versionNumber)) {
                    errors.versionNumber = "A version of apk is like that 11.04"
                }
                break;
            default:
                break;
        }
    }
    return errors;
};

export default ValidationForm;