import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DynamicForm from '../DynamicForm';
import Validation from '../ErrorValidationForm';


const ApkForm = (props) => {
    // for make the redirectory
    let history = useHistory();

    // statuts based on errors
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    // for the errors
    const [errors, setErrors] = useState({});

    // allow change the value and collect the new value
    const [values, setValues] = useState({
        apk: '',
        versionNumber: ''
    });

    const [succes, setSucces] = useState('')

    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the element of the change
     */
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Catch the name with the files
     * 
     * @param {event} event It's the element of the change
     */
    const handleFile = (event) =>{
        setValues({
            ...values,
            [event.target.name]: event.target.files[0],
        })
    }

    /**
     * When the form is finish and ok
     * 
     * @param {event} event It's the OnSubmit
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        setErrors(Validation(values));
        setDataIsCorrect(true);
        
    }

    

/**
 * When the form is correct make a DataForm for send the files and new version to the api with a fetch
 */
 useEffect(() => {
    /**
     * Allow to Change the apk and the version of the apk
     * 
     * @param {String} url Url of the server
     * @param {Object} value value of the form
     * @param {String} method It's the method of the Fetch (REST)
     * @param {String} id It's the id of the apk
     */
    async function Upload(url, value, method, id) {
        try {
            let response = await fetch(url + '/api/apkUpload/'+ id, {
                method: method,
                body: value,
                headers: {
                    'Accept': '*/*',
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                },
            })
            if (response.status === 200) {
                setSucces('Your Apk is now update to ' + values.versionNumber);
            }
            if (response.status === 404) {
                setSucces('Error in your statement!')
            }
        } catch (err) {
                throw err;
        }
            
    }

    // Begin the function when it have not error and he clic in the button type submit
    if (Object.keys(errors).length === 0 && dataIsCorrect ===  true) {
        // Put the datafile in the FormData for send a files
        let datafile = new FormData();
        datafile.append('versionNumber', values.versionNumber);
        datafile.append('apk', values.apk, 'Harta-deșeurilor.apk');
        Upload(props.url, datafile, props.method, props.data._id)
    }

}, [errors, props, dataIsCorrect, values, history]);

    // Insert all the element you need in the form
    const inputs = [
        {
            id: "apkfile", title: "Add APK file", 
            name: "apk", type: 'file', classinput:'upload',
            classdiv:'fileUpload',
            accept: ".apk", handlechange: handleFile, error: errors.apk
        },
        {
            id: "apkversion", placeholder: "version number", 
            value: values.versionNumber, name: "versionNumber", type: 'text',
            classinput:'searchApk', handlechange: handleChange, error: errors.versionNumber
        }
    ];

    return (
        <div>
            
            <form className="boxUpload" encType="multipart/form" onSubmit={handleSubmit}>
                {/* Creation input with DynamicForm */}
            
                <DynamicForm inputs={inputs} />
                <div className="boxSubmitUpload">
                <input className="submitUpload" type="submit" value="Upload Apk"/>
                <p className="apksucces">{succes}</p>
                </div>
            </form>
            
        </div>
    );
};

export default ApkForm;