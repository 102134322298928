import React from 'react';

const Textarea = (props) => {

    return (
        <div id={props.id} className={props.classdiv}>
            <label className={props.classlabel}>{props.title}</label>
            <textarea className= {props.classinput} value={props.value} name={props.name} maxLength='500' onChange={props.handlechange}/>
        </div>
    );
};

export default Textarea;