import React from 'react';
import LoginForm from '../components/Forms/LoginForm/LoginForm';
import ModalResetPassword from '../components/Modals/ModalResetPassword';

const Login = (props) =>{

    // let date = '22';
    // console.log(date);
    // console.log(new Date(date).setUTCDate);


    return (
        <>
            <section className="sectionM">
                <div className="formSection sectionCenterSpace02">
                    <h1>Harta deșeurilor</h1>

                    <a href="https://www.qries.com/" target="_blank">
                        <img className="logoPartenaire01" src="./img/Active_citizens_fund_01.png" alt="logotype Active citizens fund" ></img>
                    </a>

                    <div className="boxForm modal02">
                        <LoginForm
                            url={props.url}
                        />
                        <ModalResetPassword 
                            url={props.url}
                            name="Forgot password"
                            button="Forgot password"
                            class="buttonPassword"
                        />
                    </div>
                    <p className="disclaimer01" >Proiectul este derulat de Asociația de Ecoturism din România, în parteneriat cu Asociația de Ecoturism Țara Dornelor, Asociația de Turism Retezat și Asociația Măgura Ecoturistică și beneficiază de o finanțare în valoare de 249.997,07 euro, prin programul Active Citizens Fund România, finanțat de Islanda, Liechtenstein și Norvegia prin Granturile SEE 2014-2021. Conținutul acestei aplicații nu reprezintă în mod necesar poziția oficială a Granturilor SEE și Norvegiene 2014-2021; pentru mai multe informații accesați <a href="www.eeagrants.org" target="_blank">www.eeagrants.org</a>. Informații despre Active Citizens Fund România sunt disponibile la <a href="www.activecitizensfund.ro" target="_blank">www.activecitizensfund.ro</a></p>
                </div>
                <div className="boximgAnim">
                    <img    className="imgSection01h"
                            srcSet="./img/boy_pose_01_shadow.png 756w, ./img/boy_pose_01_shadow_280.png 400w"
                            sizes="(max-width: 700px) 400px, 756px"
                            src="./img/boy_pose_01complet.png"
                            alt="un homme relaxer flottant au dessus d'un végetal"
                    />
                    <img className="imgSection01l" src="./img/leaf_01_shadow.png" alt="une plante verte"></img>
                </div>
                
            </section>

        </>
    );
};

export default Login;