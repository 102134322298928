import React, { useState } from 'react';
import ForgotPasswordForm from '../Forms/ForgotPassword/ForgotPasswordForm';
import ForgotPasswordSucces from '../Forms/ForgotPassword/ForgotPasswordSucces';

const ModalResetPassword = (props) => {

    const [isOpen, setIsOpen] = useState(false)

    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const submitForm = () => {
        setFormIsSubmitted(true);
    };
    return (
        <div className="modal">
            <button className={props.class} onClick={() => setIsOpen(!isOpen)}>{props.button}</button>{isOpen ? (
            <div className="addtest">
            <div className="modalV modal01">
                <h3>{props.name}</h3>
                {!formIsSubmitted ? (<ForgotPasswordForm 
                    url={props.url}
                    submitForm={submitForm}
                />) : (<ForgotPasswordSucces />)}
            </div>
            <div className='badmodal' onClick={() => {setIsOpen(false); setFormIsSubmitted(false)}}>
            </div>
            </div>
            ) : null}
        </div>
    );
};

export default ModalResetPassword;