import React from 'react';

const Search = (porps) => {

    return (
        <div>
            <input
                className={porps.class}
                type="text"
                placeholder="Search"
                value={porps.value}
                onChange={(e) => porps.onchange(e.target.value)}
            />
        </div>
    );
};

export default Search;


/**
 * 
 * @param {*} props 
 */
const FunctionSearch = (data, columns, text) => {
    /**
     * Allows multiple words to be used for the search
     * 
     * @param {string || array || object} row It is the data
     * @param {string || array} columns It is the value of the data
     * @returns 
     */
     function searchfilter(row, columns, text) {
        const words = text.toLowerCase().split(' ');
        let result = columns.some((column) => row[column].toString().toLowerCase().indexOf(words[0]) > -1);
        // add a filter with new words to result
        for (let i = 1; i < words.length; i++) {
            const filter = columns.some((column) => row[column].toString().toLowerCase().indexOf(words[i]) > -1);
            result = result && filter;
        }
        return result;
    }

    /**
     * function search for the datatable
     * 
     * @param {array || object} rows 
     * @param {array || string} columns 
     * @returns 
     */
    function search(rows, columns, text) {
        return rows.filter((row) => searchfilter(row, columns, text));
    }

    return search(data, columns, text);
};

const FunctionSearchTrash = (data, columns, text) => {


    /**
     * Allows multiple words to be used for the search
     * 
     * @param {string || array || object} row It is the data
     * @param {string || array} columns It is the value of the data
     * @returns 
     */
     function searchfilter(row, columns,text) {
        const words = text.toLowerCase().split(' ');
        let result = columns.some((column) => {
            if (row[column] !== undefined) {
                return row[column].toString().toLowerCase().indexOf(words[0]) > -1
            } else {
                // return console.log(">>>>>>>>>>>>>> ",column, row[column]);
            }
        });
        // add a filter with new words to result
        for (let i = 1; i < words.length; i++) {
            const filter = columns.some((column) => {
                if (row[column !== undefined]) {
                    return row[column].toString().toLowerCase().indexOf(words[i]) > -1;
                } else {
                    // return console.log(">>>>>>>>>>>>>> ",column, row[column]);
                }
            });
            result = result && filter;
        }
        return result;
    }

    /**
     * function search for the datatable
     * 
     * @param {array || object} rlogows 
     * @param {array || string} columns 
     * @returns 
     */
    function search(rows, columns, text) {

        // Allow to catch all data.report and data.cleaned
        let datareport
        let dataclean
        rows.map((t) => {
            datareport = t.report;
            dataclean = t.cleaned;
            return false;
        })

        // Array Key of report
        let columnreport = datareport && Object.keys(datareport);
        // Array Key of cleaned
        let columnclean = dataclean && Object.keys(dataclean);

        return rows.filter((row) => {
            return searchfilter(row, columns, text) || searchfilter(row.report, columnreport, text) || searchfilter(row.cleaned, columnclean, text)
        });
    }

    return search(data, columns, text);

};

export {FunctionSearch,FunctionSearchTrash};