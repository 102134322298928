import React, { useState } from 'react';

const ModalCarouselImage = (props) => {

    // change the state of the modal
    const [isOpen, setIsOpen] = useState(false);

    const [current, setCurrent] = useState(0);
    const length = props.img.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if (!Array.isArray(props.img) || props.img.length <= 0) {
        return null;
    }

    if (props.modal === true) {
        return (
            <div className="modal ">
                {/* if isOpen is false open the modal and I use two div for make the close clic the modal*/}
                <img src={props.photo ? props.photo + props.img[0] : props.url + props.img[0]} className={props.class} onClick={() => setIsOpen(!isOpen)} />
                {isOpen ? (
                    <div className="addtest">
                        <div className="modalCarrousel01">

                            {props.img.map((image, i) => {
                                return (
                                    <div className={i === current ? 'slide active' : 'slide'} key={i}>
                                        {i === current && (<img src={props.url + image} alt={`Trash ${i}`} className={props.class} />)}
                                    </div>
                                )
                            })}
                            <div className="BoxSlide">
                                <button onClick={prevSlide}>&#10094;</button>
                                <button onClick={nextSlide}>&#10095;</button>
                            </div>
                        </div>
                        <div className='badmodal' onClick={() => setIsOpen(false)}>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
    if (props.modal === false) {
        return (
            <div className="">

                {props.img.map((image, i) => {
                    return (
                        <div className={i === current ? 'slide active' : 'slide'} key={i}>
                            {i === current && (<img src={props.url + image} alt={`Trash ${i}`} className={props.class} />)}
                        </div>
                    )
                })}
                <div className="BoxCardSlide">
                    <button onClick={prevSlide}>&#10094;</button>
                    <button onClick={nextSlide}>&#10095;</button>
                </div>
            </div>
        );
    }
};

export default ModalCarouselImage;