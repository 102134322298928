import ModalForms from "../Modals/ModalForms";
import ModalValidation from "../Modals/ModalValidation";
import CarouselImage from "../Modals/ModalCarouselImage"
import Modal from "../Modals/Modal"

/**
 * Component: all the rows for DataTable
 * 
 * @param {*} props .data (all data in bdd, it's a Array with Objet) 
 * .info(parameter for column and content, it's a Array with Objet) 
 * .textSearch(word write in the search, it's a String)
 * .FunctionSearch(function for the search needed)
 * @param {*} [data] truc
 * @this
 * @returns The rows of the table with information
 */
const DataTbody = (props) => {

    // Array Key of data
    // const columns = props.data[0] && Object.keys(props.data[0]);
    
    /**
     * Generate with table the data in the row
     * 
     * @param {Array} info parameter for column and content
     * @param {Object} table data loop
     * @returns the data need for the row
     */
    function Row(info, table) {

        const handleCheck = (id) =>{
            let hidden = [...props.check];
            if (props.check.includes(id)) {
                hidden.splice(hidden.indexOf(id),1);
                props.setCheck(hidden);
            } else {
                hidden.push(id);
                props.setCheck(hidden);
            }
        }
        
        return info.map((tdata, i) => {
        switch (tdata.id) {
            case 'inputCheckbox':
                return <td key={i}><input type="checkbox" onChange={(e) => handleCheck( table._id)} checked={props.check.includes(table._id)}></input></td>;
            case 'status':
                return <td key={i}>{table.status}</td>;

            case '_id':
                return <td key={i}>{table._id}</td>;

            case 'firstName':
                return <td key={i}>{table.firstName}</td>;

            case 'lastName':
                return <td key={i}>{table.lastName}</td>;

            //Give the full name
            case 'reporter':
                return <td key={i}>{table.userFirstName + ' ' + table.userLastName}</td>;

            //Give the full name
            case 'cleaner':
                return <td key={i}>{table.cleaned.userFirstName + ' ' + table.cleaned.userLastName}</td>;

            case 'email':
                return <td key={i}>{table.email}</td>;
            
            case 'password':
                return <td key={i}>{table.password}</td>

            case 'location':
                return <td key={i}>{table.report.location}</td>;

            case 'locations':
                return <td key={i}>{table.location}</td>;

            // Add a trashtype and space for separate all the types
            case 'trashType':
                return <td key={i}>{table.report.trashType.map((trashtype) => trashtype + ' ')}</td>;

            case 'volume':
                return <td key={i}>{table.report.volume}</td>;

            case 'accessibility':
                return <td key={i}>{table.report.accessibility}</td>;

            //Change the icon if it's not the admin
            case 'group':
                return table.group === 'admin' ? 
                <td key={i} className='groupAdmin'></td> : <td key={i} className='groupUser'></td>;

            //Change the icon if it's not active
            case 'active':
                return table.active === true ?
                <td key={i} className="activeOn"></td> : <td key={i} className="activeOff"></td>;
            
            case "appVersion":
                return <td key={i}>{table.appVersion}</td>;

            case 'dateCreation':
                return <td key={i}>{table.dateCreation}</td>;

            case 'creationDate':
                return <td key={i}>{table.creationDate}</td>;

            case 'dateValidation':
                return <td key={i}>{table.dateValidation}</td>;

            case 'dateClean':
                return <td key={i}>{table.cleaned.creationDate}</td>;
                
            //Carousel to all Photo make
            case 'photo':
                return <td key={i}><CarouselImage
                                            url={tdata.url}
                                            photo={tdata.photo}
                                            img={table.report.photo.concat(table.cleaned.photo)}
                                            class={tdata.class}
                                            modal={tdata.modal}
                                        /></td>;

            //If it comment make a modal with the comment
            case 'commentReport':
                return table.report.comment === '' ?
                                            <td key={i}></td> :
                                            <td key={i}><Modal
                                                button={tdata.name}
                                                title={tdata.legend}
                                                data={table.report.comment}
                                                class={tdata.class}
                                            /></td>;

            //If it comment make a modal with the comment
            case 'commentClean':
                return table.cleaned.comment === '' ?
                                            <td key={i}></td> :
                                            <td key={i}> <Modal
                                                button={tdata.name}
                                                title={tdata.legend}
                                                data={table.cleaned.comment}
                                                class={tdata.class}
                                            /></td>;
            
            //Modal to modify the row
            case 'modify':
                return <td key={i} className="modifyTd"><ModalForms
                button={tdata.button}
                class={tdata.class}
                name={tdata.name}
                method={tdata.method}
                data={table}
                url={tdata.url}
                key={table._id}
            /></td>;

            //Modal to delete the row
            case 'delete':
                return <td key={i}><ModalValidation
                button={tdata.button}
                class={tdata.class}
                name={tdata.name}
                method={tdata.method}
                data={table}
                url={tdata.url}
                key={i}
            /></td>;

            case 'originalUrl':
                return <td key={i}>{table.originalUrl}</td>;

            case 'method':
                return <td key={i}>{table.method}</td>;

            case 'body':
                return <td key={i}>{table.body}</td>;

            case 'resStatus':
                return <td key={i}>{table.resStatus}</td>;

            default:
                return <td key={i}></td>;
        }
        }
        )};
    
    return (
        <tbody>
        {/* filter column and search. Show the data in table */}
        {props.data.map((table, i) => (
            <tr key={i}>
                {Row(props.info,table)}
            </tr>))}
    </tbody>
    );
};

export default DataTbody;