import React from 'react';

const Select = (props) => {
    return (
        <div className={props.classdiv}>
        <label className={props.classlabel}>{props.title}</label>
            <select 
                className= {props.classinput}
                name= {props.name}
                value= {props.value}
                onChange={props.handlechange}
            >
                {props.null ? <option value=''>{props.null}</option> : null}
            {/* map de data for make all the option needed */}
                {props.data.map((option) => {
                    let title = option.name.toLowerCase().split(' ').map((word)=>{return word[0].toUpperCase() + word.substr(1);}).join(' ');
                   return <option value={option.name} key={option.name} className={props.classoption + option.class}>{title}</option>
                })}
            </select>
        {props.error && <p className="error">{props.error}</p>}
        </div>
    );
};

export default Select;