import React, { useEffect, useState } from 'react';
import Validation from '../ErrorValidationForm';
import DynamicForm from '../DynamicForm';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ParseJwt } from '../../Navigation';

const LoginForm = (props) => {
    // for make the redirectory
    let history = useHistory();

    // statuts based on errors
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    const [errorPost, setErrorPost] = useState('')

    // for the errors
    const [errors, setErrors] = useState({});

    // allow change the value and collect the new value
    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the onChange
     */
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    /**
     * When the form is finish and ok
     * 
     * @param {event} event It's the OnSubmit
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        setErrors(Validation(values));
        setDataIsCorrect(true);
        
    }

    

    /**
     * Return the data need in the localStorage and make the redirection
     */
     useEffect(() => {

        /**
         * Verify the user, add in localStorage and redirect
         * 
         * @param {String} url Url of the server
         * @param {Object} value value of the form
         */
        async function Login(url, value) {
            try {
                let response = await fetch(url + '/api/login', {
                    method: 'POST',
                    body: JSON.stringify({
                        email: value.email,
                        password: value.password
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 404) {
                    setErrorPost('Your account is not active or it does\'nt exist');
                    setDataIsCorrect(false);
                }
                if(response.status === 200) {
                    let data = await response.json();
                localStorage.setItem('Token', data.token);
                    if (ParseJwt(localStorage.getItem('Token')).group === 'admin' || ParseJwt(localStorage.getItem('Token')).group === 'superAdmin') {
                        history.push('/admin-user');
                    } else if (ParseJwt(localStorage.getItem('Token')).group === 'user') {
                        localStorage.setItem('Group', data.user.group);
                        localStorage.removeItem('Token');
                        history.push('/download');
                    }
                }
            } catch (error) {
                throw error;
            }
        }

        // Begin the function when it have not error and he clic in the button type submit
        if (Object.keys(errors).length === 0 && dataIsCorrect ===  true) {
            Login(props.url, values)
        }

    }, [errors, props, dataIsCorrect, values, history]);

    // Insert all the element you need in the form
    const inputs = [
        {
            id: "emaillog", title: "Email", value: values.email,
            type: "email", name: "email",
            handlechange: handleChange, error: errors.email,
            classdiv: "forma formEmail"
        },
        {
            id: "passwordlog", title: "Password", value: values.password,
            name: "password", type: "password", classdiv: "forma formPassw",
            handlechange: handleChange, error: errors.password,
        }
    ];

    return (
        <form onSubmit={handleSubmit}>

            <div className="error02" >{errorPost}</div>
            {/* Creation input with DynamicForm */}
            <DynamicForm inputs={inputs} />
            <div className="boxButton">
                {/* NavLink work like <a href=""></a> */}
                <NavLink className="buttonSign" exact to="register">
                    Sign up
                </NavLink>
                <button className="buttonSign" type="submit">Log In</button>
            </div>
        </form>
    );
};

export default LoginForm;