import React from 'react';
import { NavLink } from 'react-router-dom';

const RegistrationSucces = () => {
    return (
        // when the registration is succesfully
        <div>
            Your Request have been correctly send! Wait a moderator to accepte your account.
            <p>Return to <NavLink exact to="/">login</NavLink></p>
        </div>
    );
};

export default RegistrationSucces;