import React from 'react';

const TextInput = (props) => {
    
        return (
            <div id={props.id} className={props.classdiv}>
                <label className={props.classlabel}>{props.title}</label>
                {/* for the checked, radio and checkbox use it but they are different so I change the method depending on the type */}
                <div className="switch">
                <input id="switch-1" className="switch-input" type={props.type} value={props.value} name={props.name} onChange={props.handlechange} checked={props.checked} />
                <label for="switch-1" className="switch-label">Switch</label>
                </div>
                <span className={props.classspan}></span>
                {props.error && <p className="error">{props.error}</p>}
            </div>
        );

   
};

export default TextInput;