import React, { useEffect, useState } from 'react';
import Search, { FunctionSearch } from '../../components/Inputs/Search';
import Navigation, { Redirection } from '../../components/Navigation';
import { useHistory } from 'react-router';
import Pagination from '../../components/Table/Pagination';
import { DateSecondAnne } from '../../components/FormatDate';
import DataTable from '../../components/Table/DataTable';


const Log = (props) => {

    //React-Router Hook to make redirects
    let history = useHistory();

    Redirection('admin');

    // change the value of the input text of search
    const [textSearch, setTextSearch] = useState('');

    // change the value 'ASC' by 'DSC'
    const [order, setOrder] = useState('ASC');

    const [paginationPage, setPaginationPage] = useState(1);

    const [pageMax, setPageMax] = useState();

    // Allow to add and change(the data you need) the data
    const [data, setData] = useState([]);

    const handleSearch = (value) => {
        setTextSearch(value);
        setPaginationPage(1);
    }

    //Allow to wait the data Logs
    useEffect(() => {

        /**
         * Collect the Logs to the data base (GET)
         * 
         * @param {string} url url of the site
         * @param {number} page Actual Page (for the pagination)
         * @param {number} limits Limit data need (for the pagination)
         */
        async function Logs(url, page, limits, search) {
            // console.log(search);
            try {
                let response = await fetch(url + "/api/logs?" + new URLSearchParams({
                    page: page,
                    limit: limits,
                    search: search
                }).toString(), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                    }
                });
                // console.log(response);
                if (response.status === 401 || response.status === 403) {
                    localStorage.clear();
                    history.push('/');
                }
                let data = await response.json();
                data.logs.map((date) => {
                    date.creationDate = DateSecondAnne(date.creationDate, 2);
                    return null;
                })
                setData(data.logs);
                setPageMax(data.pagesMax);
            } catch (error) {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
                throw error;
            }
        }
        Logs(props.url, paginationPage, props.paginationLimit, textSearch.split(' ').join('+'));
    }, [history, props, paginationPage, textSearch]);

    /**
     * Parametre the Column and the content
     * 
     * id : key of data you need
     * column : key of data for the column (optional)
     * title : Title of the column
     * url : url of api (For photo, modify and delete)
     * class : add a class (actualy it is for photo, comment, delete and modify)
     * name : give a title for modal and content button comment (For comment, modify and delete)
     * method : method for the api (for modify and delete)
     * legend : title for comment
     */
    let information = [
    { id: "creationDate", column:"creationDate", title: "Date" },
    { id: "originalUrl", column:"originalUrl", title: "Route" },
    { id: "method", column:"method", title: "Method" },
    { id: "body", title: "Body" },
    { id: "resStatus", column:"resStatus", title: "Status" },
];

    return (
        <div className="boxPageAdmin">
            <Navigation
                classLog='activeNav'
            />
            <div className="box2">

                <div className="top">
                <Search
                            class='search01'
                            value={textSearch}
                            onchange={handleSearch}
                        />
                </div>

                <div className="boxTable">
                <DataTable
                        textSearch={textSearch}
                        data={data}
                        setData={setData}
                        order={order}
                        setOrder={setOrder}
                        info={information}
                        FunctionSearch={FunctionSearch}
                    />
                </div>
                <div className="pagination">
                    <Pagination 
                        actualpage={paginationPage}
                        changepage={setPaginationPage}
                        pagemax={pageMax}
                    />
                </div>
            </div>
        </div >
    );
};

export default Log;