import React, { useEffect, useState } from 'react';
import Navigation, { Redirection } from '../../components/Navigation';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L, { MarkerCluster } from 'leaflet';
import Url from '../../Url.json';
import { useHistory } from 'react-router';
import MarkerClusterGroup from "react-leaflet-markercluster";


import icon from '../../media/picto/picto_iconmmap_black_02.svg';
import icon2 from '../../media/picto/picto_iconmmap_green_02.svg';
import CarouselImage from '../../components/Modals/ModalCarouselImage';
import { DateJourAnnee, FormatInputDateBefore, FormatInputDateNow } from '../../components/FormatDate';
import FilterTrash from '../../components/FilterTrash';

// Create a new Icon who can be integrate in Marker with icon='...'
//If you want more tools for create the new icon you can go https://roblahoda.com/blog/leaflet-react-custom-icons/
const Icontest = new L.Icon({
    iconUrl: icon2,
    iconSize: [40, 40],
    popupAnchor: [1, -30]
});
//Export the Icon
export { Icontest };

//Config the Default Icon
let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [40, 40],
    popupAnchor: [1, -30]
});
//Config the icon
export { DefaultIcon };

// NOTE: iconCreateFunction is running by leaflet, which is not support ES6 arrow func syntax
// eslint-disable-next-line
// const createClusterCustomIcon = function (cluster = MarkerCluster) {
//     if (cluster.getChildCount() > 50) {
//         return L.divIcon({
//             html: `<span>${cluster.getChildCount()}</span>`,
//             className: 'custom-marker-cluster',
//             iconSize: L.point(33, 33, true),
//           })
//     } else {
//         return L.divIcon({
//             html: `<span>${cluster.getChildCount()}</span>`,
//             className: 'inf',
//             iconSize: L.point(33, 33, true),
//           })
//     }
//   }

// export {createClusterCustomIcon};


const Maps = (props) => {
    
    //React-Router Hook to make redirects
    let history = useHistory();
    
    Redirection('admin');

    let today = new Date();

    
    const [data, setData] = useState([]);

    const [locations, setLocations] = useState([]);

    const [filtre, setFiltre] = useState({
        firstDate: FormatInputDateBefore(today),
        secondDate: FormatInputDateNow(today),
        region: ''
    })

    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the onChange
     */
     const handleChange = (event) => {
        setFiltre({
            ...filtre,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        
        /**
         * Collect the Trash to the data base (GET)
         * 
         * @param {String} url url of the API
         * @param {*} filtre Filtre by date or/and region, if he hav'nt region do with only date
         */
        async function TrashList(url, filtre) {
            if(filtre.region === '') {
                try {
                    let response = await fetch(url + '/api/trashes/' + filtre.firstDate + '/' + filtre.secondDate, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                        }
                    });
                    let data = await response.json();
                    data.trashes.map((date) => {
                        date.creationDate = DateJourAnnee(date.creationDate, 2);
                        if (date.cleaned.creationDate) {
                            date.cleaned.creationDate = DateJourAnnee(date.cleaned.creationDate, 2);
                        }
                        if (date.gpsCoordinates.length < 2) {
                            date.gpsCoordinates = [0,0]
                        }
                        if (date.report.accessibility === 'null') {
                            date.report.accessibility = '';
                        }
                        return null;
                    })
                    setData(data.trashes);
                    if (response.status === 401 || response.status === 403) {
                        localStorage.clear();
                        history.push('/');
                    }
                } catch (error) {
                    if (error) {
                        localStorage.clear();
                        history.push('/');
                    }
                throw error;
                }
            } else {
                try {
                    let response = await fetch(url + '/api/trashes/' + filtre.region + '/' + filtre.firstDate + '/' + filtre.secondDate, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                        }
                    });
                    let data = await response.json();
                    data.trashes.map((date) => {
                        date.creationDate = DateJourAnnee(date.creationDate, 2);
                        if (date.cleaned.creationDate) {
                            date.cleaned.creationDate = DateJourAnnee(date.cleaned.creationDate, 2);
                        }
                        if (date.gpsCoordinates.length !== 2) {
                            date.gpsCoordinates = [0,0]
                        }
                        return null;
                    })
                    setData(data.trashes);
                    if (response.status === 401 || response.status === 403) {
                        localStorage.clear();
                        history.push('/');
                    }
                } catch (error) {
                    if (error) {
                        localStorage.clear();
                        history.push('/');
                    }
                throw error;
                }
            }
        }

        /**
         * Get all Region
         * 
         * @param {String} url url of the Api
         */
        async function Location(url){
            try {
                let response = await fetch(url + '/api/locations', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                    }
                });
                let data = await response.json();
                setLocations(data.locations);
            } catch (error) {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
                throw error;
            }
        }

        Location(props.url);
        TrashList(props.url, filtre);
    }, [props, filtre, history]);

    
    // Change the Icon by default because, it have a problem
    L.Marker.prototype.options.icon = DefaultIcon;

    function Length(length, data) {
        let number = 0;
        for (let index = length; index < data.length; index++) {
            number++;
        }
        if (data.length === 1) {
            return data[0];
        } else if (data.length === 2) {
            return data[0] + ', ' + data[1] + '.';
        } else if (data.length === 3) {
            return data[0] + ', ' + data[1] + ', ' + data[2] + '.';
        } else {
            return data[0] + ', ' + data[1] + ', ' + data[2] + ' +' + number;
        }
    }

    /**
     * Change the Icon if it is clean or not
     * 
     * @param {number} coorX Geographic horizontal coordinate
     * @param {number} coorY Geographic vertical coordinate
     * @param {boolean} clean If the trash is clean or not
     * @returns the marker with the good icone and coordinate.
     */
    function machin(id, coor, data) {
        coor = [coor[0], coor[1]]
        if (data.cleaned.creationDate) {
            //Marker make the icon and popup display the message
            let image = data.report.photo.concat(data.cleaned.photo);
            return (<Marker position={coor} className="g" icon={Icontest} key={id}>
                <Popup className="card01">
                    <CarouselImage
                        url={Url.url + "/api/uploads/mini/"}
                        img={image}
                        class='image'
                        modal={false}
                    />
                    <div className="boxCardText">
                        <div className="boxCardDate">
                            <p className="cardDateReported">reported<br/> {data.creationDate}</p>
                            <p className="cardDateCleaned">cleaned<br/> {data.cleaned.creationDate}</p>
                        </div>

                        <ul>
                            <li className="cardId"><div></div><p>{data._id}</p></li>
                            <li className="cardNameReported"><div></div><p>{data.userFirstName + '' + data.userLastName}</p></li>
                            <li className="cardNameCleaner"><div></div><p>{data.cleaned.userFirstName + ' ' + data.cleaned.userLastName}</p></li>
                            <li className="cardTrash"><div></div><p>{Length(3, data.report.trashType)}</p></li>
                            <li className="cardAcces"><div></div><p>{data.report.accessibility}</p></li>
                        </ul>
                    </div>
                </Popup>
            </Marker>)
        } else {
            return (<Marker position={coor} className="g" key={id}>
                <Popup className="card01">
                    <CarouselImage
                        url={Url.url + "/api/uploads/mini/"}
                        img={data.report.photo}
                        class='image'
                        modal={false}
                    />
                    <div className="boxCardText">
                        <div className="boxCardDate">
                            <p className="cardDate">{data.creationDate}</p>
                        </div>
                        <ul>
                            <li className="cardId"><div></div><p>{data._id}</p></li>
                            <li className="cardName"><div></div><p>{data.userFirstName + ' ' + data.userLastName}</p></li>
                            <li className="cardTrash"><div></div><p>{Length(3, data.report.trashType)}</p></li>
                            <li className="cardAcces"><div></div><p>{data.report.accessibility}</p></li>
                        </ul>
                    </div>
                </Popup>
            </Marker>)
        }
    };

    return (
        <section className="sectionAdmin sectionMap">
            <Navigation
                classMap='activeNav'
            />
            <div>

            </div>
            <div className="legend">
                <legend className="legendNotClean">Dirty: <img src={icon} className="" alt="" /></legend>
                <legend className="legendClean">Clean: <img src={icon2} alt="" className="" /></legend>
                <FilterTrash
                    filtre={filtre}
                    data={locations}
                    handleChange={handleChange}
                    />
            </div>
            <MapContainer center={[46.18825, 24.89761]} zoom={7} scrollWheelZoom={true} className='map'>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* allow to make a new icon with the coordinate and data in the bdd */}
                <MarkerClusterGroup
                    // iconCreateFunction={createClusterCustomIcon}
                >
                    {data.map((trash) => machin(trash._id, trash.gpsCoordinates, trash))}
                </MarkerClusterGroup>
            </MapContainer>
        </section>
    );
};

export default Maps;