import React, { useEffect, useState } from 'react';
import Search from '../../components/Inputs/Search';
import Navigation, { Redirection } from '../../components/Navigation';
import { useHistory } from 'react-router';
import Pagination from '../../components/Table/Pagination';
import DataTable from '../../components/Table/DataTable';
import { DateSecondAnne, FormatInputDateBefore, FormatInputDateNow } from '../../components/FormatDate';
import { Link } from 'react-router-dom';
import FilterTrash from '../../components/FilterTrash';


const Trash = (props) => {

    //React-Router Hook to make redirects
    let history = useHistory();

    let today = new Date();

    Redirection('admin');

    // change the value of the input text of search
    const [textSearch, setTextSearch] = useState('');
    // change the value 'ASC' by 'DSC'
    const [order, setOrder] = useState('ASC');

    const [paginationPage, setPaginationPage] = useState(1);

    const [pageMax, setPageMax] = useState();

    // Allow to add and change(the data you need) the data
    const [data, setData] = useState([]);

    const [locations, setLocations] = useState([])

    const [filtre, setFiltre] = useState({
        firstDate: FormatInputDateBefore(today),
        secondDate: FormatInputDateNow(today),
        region: ''
    });

    const [remove, setRemove] = useState([]);


    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the onChange
     */
     const handleChange = (event) => {
        setFiltre({
            ...filtre,
            [event.target.name]: event.target.value,
        });
    };

    const handleSearch = (value) => {
        setTextSearch(value);
        setPaginationPage(1);
    }

    //Allow to wait the data User
    useEffect(() => {
        
        /**
         * Collect the Trash to the data base (GET)
         * 
         * @param {String} url url of the API
         * @param {*} filtre Filtre by date or/and region, if he hav'nt region do with only date
         */
        async function TrashList(url, filtre, page, limits, search) {
            console.log(filtre.firstDate);
            console.log(filtre.secondDate);
            if(filtre.region === '') {
                try {
                    let response = await fetch(url + '/api/trashes/' + filtre.firstDate + '/' + filtre.secondDate + '?' + new URLSearchParams({
                        page: page,
                        limit: limits,
                        search: search
                    }).toString(), {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                        }
                    });
                    console.log(response);
                    let data = await response.json();
                    // console.log(data);
                    // Change all null by ' '
                    data.trashes.map((change) => {
                        change.creationDate = DateSecondAnne(change.creationDate, 2);
                        // console.log('test');
                        if (change.cleaned.creationDate) {
                            change.status = 'Clean';
                            change.cleaned.creationDate = 
                            DateSecondAnne(change.cleaned.creationDate, 2);
                        } else {
                            change.status = 'Dirty';
                            change.cleaned.creationDate = '';
                        }
                        if (!change.report.accessibility || change.report.accessibility === 'null') {
                            change.report.accessibility = '';
                        }
                        if (!change.report.volume || change.report.volume === 'null') {
                            change.report.volume = '';
                        }
                        if (!change.report.comment) {
                            change.report.comment = '';
                        }
                        if (!change.report.location || change.report.location === 'region') {
                            change.report.location = '';
                        }
                        if (!change.cleaned.userFirstName) {
                            change.cleaned.userFirstName = '';
                        }
                        if (!change.cleaned.userLastName) {
                            change.cleaned.userLastName = '';
                        }
                        if (!change.cleaned.comment) {
                            change.cleaned.comment = '';
                        }
                        if (!change.cleaned.userId) {
                            change.cleaned.userId = '';
                        }
                        return null;
                    })
                    // console.log(data.trashes);
                    setData(data.trashes);
                    setPageMax(data.pagesMax);
                    if (response.status === 401 || response.status === 403) {
                        localStorage.clear();
                        history.push('/');
                    }
                } catch (error) {
                    if (error) {
                        localStorage.clear();
                        history.push('/');
                    }
                }
            } else {
                try {
                    let response = await fetch(url + '/api/trashes/' + filtre.region + '/' + filtre.firstDate + '/' + filtre.secondDate + '?' + new URLSearchParams({
                        page: page,
                        limit: limits,
                        search: search
                    }).toString(), {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                        }
                    });
                    console.log(response);
                    let data = await response.json();
                    // Change all null by ' '
                    data.trashes.map((change) => {
                        change.creationDate = DateSecondAnne(change.creationDate, 2);
                        if (change.cleaned.creationDate) {
                            change.status = 'Clean';
                            change.cleaned.creationDate = 
                            DateSecondAnne(change.cleaned.creationDate, 2);
                        } else {
                            change.status = 'Dirty';
                            change.cleaned.creationDate = '';
                        }
                        if (!change.report.accessibility || change.report.accessibility === 'null') {
                            change.report.accessibility = '';
                        }
                        if (!change.report.volume || change.report.volume === 'null') {
                            change.report.volume = '';
                        }
                        if (!change.report.comment) {
                            change.report.comment = '';
                        }
                        if (!change.report.location || change.report.location === 'region') {
                            change.report.location = '';
                        }
                        if (!change.cleaned.userFirstName) {
                            change.cleaned.userFirstName = '';
                        }
                        if (!change.cleaned.userLastName) {
                            change.cleaned.userLastName = '';
                        }
                        if (!change.cleaned.comment) {
                            change.cleaned.comment = '';
                        }
                        if (!change.cleaned.userId) {
                            change.cleaned.userId = '';
                        }
                        return null;
                    })
                    setData(data.trashes);
                    setPageMax(data.pagesMax);
                    if (response.status === 401 || response.status === 403) {
                        localStorage.clear();
                        history.push('/');
                    }
                } catch (error) {
                    if (error) {
                        localStorage.clear();
                        history.push('/');
                    }
                }
            }
        }

        /**
         * Get all Region
         * 
         * @param {String} url url of the Api
         */
        async function Location(url){
            try {
                let response = await fetch(url + '/api/locations');
                let data = await response.json();
                setLocations(data.locations);
            } catch (error) {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
                throw error;
            }
        }

        Location(props.url);
        TrashList(props.url, filtre, paginationPage, props.paginationLimit, textSearch.split(' ').join('+'));
    }, [props, filtre, history, paginationPage, textSearch]);

    // console.log(remove);

    /**
     * Parametre the Column and the content
     * d
     * id : key of data you need
     * column : key of data for the column (optional)
     * title : Title of the column
     * url : url of api (For photo, modify and delete)
     * class : add a class (actualy it is for photo, comment, delete and modify)
     * name : give a title for modal and content button comment (For comment, modify and delete)
     * method : method for the api (for modify and delete)
     * legend : title for comment
     */
    let information = [
    { id: "inputCheckbox", title: ""},
    { id: "status", column:"status", title: "Status" },
    { id: "_id", column:"_id", title: "Code Trash" },
    { id: "reporter", column:"userLastName", title: "Reporter" },
    { id: "cleaner", title: "Cleaner"},
    { id: "location", title: "Location" },
    { id: "trashType", title: "Type Trash" },
    { id: "volume", title: "Volume" },
    { id: "accessibility", title:"Accessibility"},
    { id: "photo", title: "Photo", url: props.url + "/api/uploads/", photo: props.url + "/api/uploads/mini/", class:'imaget', modal:true },
    // { id: "creationDate", column:"creationDate", title: "Date report" },
    { id: "dateClean", title: "Date Clean" },
    { id: "commentReport", title: "Comment Report", name:'view comment', legend:'Comment', class:'buttonModalCom' },
    { id: "commentClean", title: "Comment Clean", name:'view comment', legend:'Comment', class:'buttonModalCom' },
    { id: "delete", title: "", name:'Delete Trash', url: props.url + '/api/trash/', method:'DELETE', class:'buttonDelete'},
];
    return (
        <div className="boxPageAdmin">
            <Navigation
                classTrash='activeNav'
            />

            <div className="box2">

                <div className="top">

                    <Search
                        class='search01'
                        value={textSearch}
                        onchange={handleSearch}
                    />

                    <FilterTrash
                        filtre={filtre}
                        data={locations}
                        remove={remove}
                        url={props.url}
                        handleChange={handleChange}
                        />
                </div>


                <div className="boxTable">
                <DataTable
                        // textSearch={textSearch}
                        data={data}
                        setData={setData}
                        order={order}
                        setOrder={setOrder}
                        info={information}
                        check={remove}
                        setCheck={setRemove}
                        // FunctionSearch={FunctionSearchTrash}
                    />
                </div>
                <div className="pagination">
                    <Pagination
                        actualpage={paginationPage}
                        changepage={setPaginationPage}
                        pagemax={pageMax}

                    />
                <Link className="exportButton" to={{pathname: filtre.region === '' ? props.url + '/api/csv/trashes/' + filtre.firstDate + '/' + filtre.secondDate : props.url + '/api/csv/trashes/' +  filtre.region + '/' + filtre.firstDate + '/' + filtre.secondDate}} target='_blank'>Export in csv</Link>
                </div>
            </div>
        </div>
    );
};

export default Trash;