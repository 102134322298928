import React from 'react';
import Select from '../Inputs/SelectInput';
import Textarea from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import CheckboxInput from '../Inputs/CheckboxInput';

const DynamicForm = (props) => {

    // Exemple of how you must content your varaible
    //const inputs = [{ id: "testuniq", title: "testlabel", value: values.test,
    // type: "testtype", name: "testname",
    // handlechange : handleChange, error : errors.test },]
    const inputsComponents = props.inputs.map((input) => {
        switch (input.type) {
            case 'text':
                return <TextInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // Put a placeholder
                    placeholder={input.placeholder}
                    // it's the varaible values.email you make on useState
                    value={input.value}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                    // Give a className for div
                    classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />;
            case 'password':
                return <TextInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // it's the varaible values.email you make on useState
                    value={input.value}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                     // Give a className for div
                     classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />;
            case 'email':
                return <TextInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // it's the varaible values.email you make on useState
                    value={input.value}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                     // Give a className for div
                     classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />;
            case 'textarea':
                return <Textarea
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Name of the input
                    name={input.name}
                    // it's the varaible values.textarea you make on useState
                    value={input.value}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                     // Give a className for div
                     classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />;
            case 'radio':
                return <TextInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // Give the value you want put
                    value={input.value}
                    // it's the varaible values.gender you make on useState
                    checked={input.checked}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                     // Give a className for div
                     classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />;
            case 'file':
                return <TextInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    //Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Type of file you need
                    accept={input.accept}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                    // Give a className for div
                    classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                />
            case 'checkbox':
                return <CheckboxInput
                    // Give a id unique
                    id={input.id}
                    // Title of label
                    title={input.title}
                    // Type of the input
                    type={input.type}
                    // Name of the input
                    name={input.name}
                    // it's the varaible values.gender you make on useState
                    checked={input.checked}
                    // Put the handlechange for the OnChange
                    handlechange={input.handlechange}
                    // Give a error
                    error={input.error}
                    // Give a key unique or you have a problem
                    key={input.id}
                     // Give a className for div
                     classdiv={input.classdiv}
                    // Give a className for label
                    classlabel={input.classlabel}
                    // Give a className for input
                    classinput={input.classinput}
                    // Give a className for input
                    classspan={input.classspan}
                />;
                case 'select':
                    return <Select
                        // Give a id unique
                        id={input.id}
                        // Title of label
                        title={input.title}
                        //select the value by default
                        value={input.value}
                        // Name of the input
                        name={input.name}
                        // it's a Object, it have a title and value for the option.
                        data={input.data}
                        //Put a option with no value
                        null={input.null}
                        // Put the handlechange for the OnChange
                        handlechange={input.handlechange}
                        //error if you have a null
                        error={input.error}
                        // Give a key unique or you have a problem
                        key={input.id}
                         // Give a className for div
                        classdiv={input.classdiv}
                        // Give a className for label
                        classlabel={input.classlabel}
                        // Give a className for input
                        classinput={input.classinput}
                        // Give a className for input
                        classoption={input.classoption}
                    />;
            default:
                // Return nothing
                return false;
        }
    });


    return (
        <div className="boxForma02">
            {inputsComponents}
        </div>
    );
};

export default DynamicForm;