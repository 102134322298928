import React, { useState } from 'react';
import RegisterForm from '../components/Forms/RegisterForm/RegistrationForm';
import RegistrationSucces from '../components/Forms/RegisterForm/RegistrationSucces';


const Form = (props) => {
    const [formIsSubmitted, setFormIsSubmitted] = useState(false)

    const submitForm = () => {
        setFormIsSubmitted(true);
    };
    return (
        <section className="sectionM">
            <div className="formSection sectionCenterSpace02">
                <h1>Harta deșeurilor</h1>
                <div className="boxForm modal02 boxFormR sectionCenter02">
                    {/* When you have succefully register(RegisterForm), it show you a message (RegistrationSucces)*/}
                    {!formIsSubmitted ? (<RegisterForm  
                        url={props.url}
                        submitForm={submitForm} 
                    />) : (<RegistrationSucces />)}
                </div>
            </div>
            <img className="imgSection03" src="./img/boy_pose_02b.png" alt=""></img>
        </section>
    );
};

export default Form;