const FormatDate = () => {
    
};

export default FormatDate;

/**
     * Change the style of the date with the style yyyy(-1y)-mm-dd
     * 
     * @param {date} date Date you need to change
     * @returns the date with date, month and year
     */
 const FormatInputDateBefore = (date) => {
     date.setMonth(date.getMonth() -2)
    var dd = String(date.getUTCDate()).padStart(2, '0');
    var mm = String(date.getUTCMonth() +1).padStart(2, '0'); //January is 0!
    var yyyy = date.getUTCFullYear();

    return yyyy + '-' + mm + '-' + dd;
};

/**
     * Change the style of the date with the style yyyy-mm-dd
     * 
     * @param {date} date Date you need to change
     * @returns the date with date, month and year
     */
const FormatInputDateNow = (date) => {
     date.setMonth(date.getMonth() +2)
        var dd = String(date.getUTCDate()+1).padStart(2, '0');
        var mm = String(date.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getUTCFullYear();
  
        return yyyy + '-' + mm + '-' + dd;
};


/**
     * Change the style of the date with the style dd/mm/yyy
     * 
     * @param {date} date Date you need to change
     * @returns the date with date, month and year
     */
const DateJourAnnee = (date,h) => {
     if (h) {
          date = new Date(new Date(date).setTime(new Date(date).getTime() + (h*60*60*1000)));
     } else {
          date = new Date(date);
     }
        var dd = String(date.getUTCDate()).padStart(2, '0');
        var mm = String(date.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getUTCFullYear();
  
        return dd + '/' + mm + '/' + yyyy;

     // let dates = date.substring(0,10);
     // dates = dates.split('-');
     // dates = dates.reverse();
     // dates = dates.join('/');

     // return dates;
};

/**
     * Change the style of the date with the style dd/mm/yyy hh:mtmt:ss
     * 
     * @param {date} date Date you need to change
     * @returns the date with date, month, year, hours, minutes and seconds
     */
const DateSecondAnne = (date, h) => {
     if (h) {
          date =new Date(new Date(date).setTime(new Date(date).getTime() + (h*60*60*1000)));
     } else {
          date = new Date(date);
     }
    const dd = String(date.getUTCDate()).padStart(2, '0');
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getUTCFullYear();
    const hh = String(date.getUTCHours()).padStart(2, '0');
    const mtmt = String(date.getUTCMinutes()).padStart(2, '0');
    const ss = String(date.getUTCSeconds()).padStart(2, '0');
     // const heure = date.substring(11,19);
     // let dates = date.substring(0,10);
     // dates = dates.split('-');
     // dates = dates.reverse();
     // dates = dates.join('/');

     // return dates + ' ' + heure;

    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + mtmt + ':' + ss;
};

export {
    DateJourAnnee,
    DateSecondAnne,
    FormatInputDateNow,
    FormatInputDateBefore
};