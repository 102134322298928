import React, { useEffect, useState } from 'react';
import DynamicForm from '../DynamicForm';
import Validation from '../ErrorValidationForm';

const ForgotPasswordForm = ({url, submitForm}) => {
    
    const [dataIsCorrect, setDataIsCorrect] = useState(false)
    
    const [errors, setErrors] = useState({});

    const [errorPost, setErrorPost] = useState('')
    
    const [values, setValues] = useState({
        email: ''
    })

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrors(Validation(values));
        setDataIsCorrect(true);
    }

    useEffect(() => {
        async function RemenberPass(url, value){
            try {
                let response = await fetch(url + '/api/remember', {
                    method: "POST",
                    body: JSON.stringify({email: value.email}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                console.log(response);
                if (response.status === 404) {
                    setErrorPost('Email address is invalid');
                    setDataIsCorrect(false);
                }
                if (response.status === 200) {
                   submitForm();
                }
            } catch (error) {
                throw error;
            }
        }

        if(Object.keys(errors).length === 0 && dataIsCorrect === true) {
            RemenberPass(url, values)
        }
    }, [url, submitForm, errors, dataIsCorrect, values])

    const inputs = [
        {
            id: "remenberemail", title: "Enter your email", name: "email",
            type: "email", value: values.email,
            handlechange: handleChange, error: errors.email
        }
    ]

    return (
        <form className="formPassword" onSubmit={handleSubmit}>
            <DynamicForm 
                inputs={inputs}
            />
            <div className="error02" >{errorPost}</div>
            <button className="buttonPasswordModal" type="submit">Send</button>
        </form>
    );
};

export default ForgotPasswordForm;