import React, { useEffect, useState } from 'react';
import Search from '../../components/Inputs/Search';
import ModalForms from '../../components/Modals/ModalForms';
import Navigation, { Redirection } from '../../components/Navigation';
import { useHistory } from 'react-router';
import Pagination from '../../components/Table/Pagination';
import { DateJourAnnee } from '../../components/FormatDate';
import DataTable from '../../components/Table/DataTable';


const User = (props) => {

    //React-Router Hook to make redirects
    let history = useHistory();

    Redirection('admin');

    // change the value of the input text of search
    const [textSearch, setTextSearch] = useState('');
    // change the value 'ASC' by 'DSC'
    const [order, setOrder] = useState('ASC');

    // Allow to add and change(the data you need) the data
    const [data, setData] = useState([]);

    const [paginationPage, setPaginationPage] = useState(1);

    const [pageMax, setPageMax] = useState();

    const handleSearch = (value) => {
        setTextSearch(value);
        setPaginationPage(1);
    }
    
    //Allow to wait the data User
    useEffect(() => {
        
        /**
         * Collect the Users to the data base (GET)
         * 
         * @param {String} url url of the Api
         * @param {number} page Actual Page (for the pagination)
         * @param {number} limits Limit data need (for the pagination)
         */
        async function Users(url, page, limits, search) {

            try {
                let response = await fetch(url + "/api/users?" + new URLSearchParams({
                    page: page,
                    limit: limits,
                    search: search
                }).toString(), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                    }
                });
                let data = await response.json();
                // We make a map for change the data of all date
                data.users.map((date) => {
                    date.dateCreation = DateJourAnnee(date.creationDate, 2);
                    if (date.validationDate) {
                        date.dateValidation = DateJourAnnee(date.validationDate, 2)
                    } else {
                        date.validationDate = '';
                        date.dateValidation = '';
                    }
                    if (!date.location) {
                        date.location = '';
                    }
                    if (!date.appVersion) {
                        date.appVersion = '';
                    }
                    return null;
                })
                // Collect the data of users
                setData(data.users);
                setPageMax(data.pagesMax);
                // if the token is false, it return the status 401. We clear le storage and retunr to login
                if (response.status === 401 || response.status === 403) {
                    localStorage.clear();
                    history.push('/');
                }

            } catch (error) {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
            }
        }
        
        Users(props.url, paginationPage, props.paginationLimit, textSearch.split(' ').join('+'));
        
    }, [history, props, paginationPage, textSearch]);

    /**
     * Parametre the Column and the content
     * 
     * id : key of data you need
     * column : key of data for the column (optional)
     * title : Title of the column
     * url : url of api (For photo, modify and delete)
     * class : add a class (actualy it is for photo, comment, delete and modify)
     * name : give a title for modal and content button comment (For comment, modify and delete)
     * method : method for the api (for modify and delete)
     * legend : title for comment
     */
    let information = [{ id: "firstName", column:"firstName", title: "Firstname" },
    { id: "lastName", column:"lastName", title: "Lastname" },
    { id: "email", column:"email", title: "Email" },
    { id: "password", column:"password", title: "Password" },
    { id: "locations", column:"location", title: "Location"},
    { id: "group", column:"group", title: "Group" },
    { id: "active", column:"active", title: "Active" },
    { id: "appVersion", column: "appVersion", title: "appVersion", date: true},
    // { id: "dateCreation", column:"dateCreation", date: true, title: "Creation Date" },
    // { id: "dateValidation", column:"dateValidation", date: true, title: "Validation Date" },
    { id: "modify", title: "", name:'Modify User', url: props.url + '/api/user/', method:'PUT', class:'buttonModify'},
    { id: "delete", title: "", name:'Delete User', url: props.url + '/api/user/', method:'DELETE', class:'buttonDelete'}];    

    return (
        <div className="boxPageAdmin">
            <Navigation 
                classUser='activeNav'
            />

            <div className="box2">

                <div className="top">
                    {/* Input text that use the search function*/}
                    <Search
                            class='search01 searchUser'
                            value={textSearch}
                            onchange={handleSearch}
                        />
                    <div className="AdminUserTopBoxInput">
                        {/* Button add a user */}
                        <ModalForms
                            button='Add User'
                            name='Add User'
                            method='POST'
                            url={props.url + '/api/user/'}
                            key='adduser'
                        />
                    </div>

                </div>

                <div className="boxTable">
                <DataTable
                        // textSearch={textSearch}
                        data={data}
                        setData={setData}
                        order={order}
                        setOrder={setOrder}
                        info={information}
                        // FunctionSearch={FunctionSearch}
                    />
                </div>
                <div className="pagination">
                    <Pagination 
                        actualpage={paginationPage}
                        changepage={setPaginationPage}
                        pagemax={pageMax}

                    />.
                </div>
            </div>
        </div>
    );
};

export default User;