import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DynamicForm from '../DynamicForm';
import Validation from '../ErrorValidationForm';

const Register = ({ url, submitForm }) => {
    // for the errors
    const [errors, setErrors] = useState({});

    // statuts based on errors
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    // Collect all location and put in select
    const [locations, setLocations] = useState([]);

    // allow change the value and collect the new value
    const [values, setValues] = useState({
        firstname: '',
        lastname: '',
        location: '',
        email: '',
        password: '',
        password2: ''
    });

    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the onChange
     */
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    /**
     * When the form is finish and ok
     * 
     * @param {event} event It's the OnSubmit
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        setErrors(Validation(values));
        setDataIsCorrect(true);
    }

    /**
     * Return the RegistrationSuccess.jsx and the POST of the User
     */
    useEffect(() => {

        /**
         * Post a User to the register but not active so the admin need to active it
         * 
         * @param {string} url Url of the site
         * @param {string} method Method REST you need to use
         * @param {object} value All data in the input
         * @param {string} validationdate All information required but need to return nothing
         */
        async function User(url, method, value, validationdate) {
            try {
                await fetch(url + '/api/user', {
                    method: method,
                    body: JSON.stringify({
                        lastName: value.lastname,
                        firstName: value.firstname,
                        location: value.location,
                        email: value.email,
                        password: value.password,
                        group: validationdate,
                        active: validationdate,
                        validationDate: validationdate
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        if (res.status === 201) {
                            submitForm();
                        }
                    })
    
            } catch (err) {
                throw err;
            }
        }
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            User(url, 'POST', values);
        }

    }, [errors, dataIsCorrect, url, values, submitForm]);

    /**
     * Data for the select Location
     * 
     * @param {String} url Url of the server
     */
     async function Location(url) {
        try {
            let response = await fetch(url + '/api/locations');
            let data = await response.json();
            setLocations(data.locations);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        Location(url);
    }, [url]);

    // Insert all the element you need in the form
    const inputs = [
        {
            id: "firstnameregist", title: "First Name", value: values.firstname,
            type: "text", name: "firstname", classdiv: "forma formFirst",
            handlechange: handleChange, error: errors.firstname
        },
        {
            id: "lastnameregist", title: "Last Name", value: values.lastname,
            type: "text", name: "lastname", classdiv: "forma formLast",
            handlechange: handleChange, error: errors.lastname
        },
        {
            id: "locationregist", title: "Location", value: values.location,
            type: "select", name: "location", 
            classdiv: "forma", classinput: "selectFormModal",
            handlechange: handleChange, error:  errors.location,
            data: locations, null: 'Choose your location'
        },
        {
            id: "emailregist", title: "Email", value: values.email,
            type: "email", name: "email", classdiv: "forma formEmail",
            handlechange: handleChange, error: errors.email
        },
        {
            id: "passwordregist", title: "Password", value: values.password,
            name: "password", type: "password", classdiv: "forma formPassw",
            handlechange: handleChange, error: errors.password
        },
        {
            id: "password2regist", title: "Repeat Password", value: values.password2,
            type: "password", name: "password2", classdiv: "forma formPassw2",
            handlechange: handleChange, error: errors.password2
        },
    ];

    return (
        <div className="registerform">

            <form onSubmit={handleSubmit}>

                {/* Creation input with DynamicForm */}
                <DynamicForm inputs={inputs}/>
                <div className="boxButton">
                    <button className="buttonSign" type="submit">Sign Up</button>
                    <NavLink exact to="/" className="buttonSign">Sign In</NavLink>
                </div>
            </form>

        </div>
    );
};

export default Register;