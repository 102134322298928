import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Redirection } from '../../components/Navigation';


const Download = (props) => {
    Redirection('user');

    const [version, setVersion] = useState('')

    useEffect(() => {
        /**
     * Verify the user, add in localStorage and redirect
     * 
     * @param {String} url Url of the server
     * @param {Object} value value of the form
     */
    async function ApkVersion(url) {
        try {
            let response = await fetch(url + '/api/appversion', {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                },
            })
            let data = await response.json();
            let file = await data.appVersion[0];
            setVersion(file);
        } catch (error) {

        }
            
    }
    ApkVersion(props.url);
    }, [props])
    return (
        <section className="sectionM sectionCenterSpaceDownload">
            <div className="formSection sectionCenterSpace02">
                <h1 className="marginB020">Harta deseurilor</h1>
                
                <div className="sectionCenter02">
                    <p className="marginB020">This is the application mobile, download it below</p>
                    <div className="picto arrowB"></div>
                    {/* it's a <a href=""><a>, app-realease.apk is in public. The blank opens the linked document in a new window or tab */}
                    <Link className="buttonSign2" to={{ pathname: props.url + "/api/uploads/Harta-deșeurilor.apk"}} target='_blank'>Download</Link>

                    <p>The version of the app: {version.versionNumber}</p>
                </div>
            </div>
            <img className="imgSection02" src="./img/girl_pose_01_shadow.png" alt=""></img>
        </section>
    );
};

export default Download;