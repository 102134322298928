import React from 'react';
import DynamicTbody from './DynamicTbody'

/**
 * It's a composant for make Data Table
 * 
 * @param {*} props .textSearch(it's the text in the search, it's a String)
 * .data(all the data send by the api)
 * .setData(Allow to change the parameter data)
 * .order(It's the order of the column)
 * .setOrder(Allow to change the parameter order)
 * .info(It's the information need for make the column and the content)
 * .FunctionSearch(Allow to change the data need)
 * @returns The Data Table
 */
const DataTable = (props) => {

    /**
    * Ascending and descending sort
    * 
    * @param {string} col the key of the column
    */
    const sorting = (col, date) => {
        // go down
        if (props.order === 'ASC') {
            const sorted = [...props.data].sort((a, b) =>
                {if (date) {
                    return a[col].split('/').reverse().join('/').toString().toLowerCase() > b[col].split('/').reverse().join('/').toString().toLowerCase() ? 1 : -1
                } else {
                    return a[col].toString().toLowerCase() > b[col].toString().toLowerCase() ? 1 : -1
                }
            });
            props.setData(sorted);
            props.setOrder('DSC');

        }
        // go up
        if (props.order === 'DSC') {
            const sorted = [...props.data].sort((a, b) =>
            {if (date) {
                return a[col].split('/').reverse().join('/').toString().toLowerCase() < b[col].split('/').reverse().join('/').toString().toLowerCase() ? 1 : -1
            } else {
                return a[col].toString().toLowerCase() < b[col].toString().toLowerCase() ? 1 : -1
            }
        });
            props.setData(sorted);
            props.setOrder('ASC')
        }
    }
    return (
        <table>
            <thead>
                <tr>
                    {/* The function sorting is the colunm filter */}
                    {props.info.map((info, i) => {
                        if (info.column) {
                            return <th onClick={() => sorting(info.column, info.date)} key={i}>{info.title}<i></i></th>;
                        } else {
                            return <th key={i}>{info.title}</th>;
                        }
                    }
                    )}
                </tr>
            </thead>
            <DynamicTbody
                data={props.data}
                info={props.info}
                textSearch={props.textSearch}
                FunctionSearch={props.FunctionSearch}
                check={props.check}
                setCheck={props.setCheck}
            />
        </table>
    );
};

export default DataTable;