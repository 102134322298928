import React from 'react';

const ForgotPasswordSucces = () => {
    return (
        <div>
            <p>Parola va fi trimisă în căsuța dvs. de e-mail. 
                Vă rugăm să vă verificați e-mailul.</p>
        </div>
    );
};

export default ForgotPasswordSucces;