import React, { useState } from 'react';
import ModalForm from '../Forms/ModalUserForm/ModalForm';


const ModalForms = (props) => {
    // change the state of the modal
    const [isOpen, setIsOpen] = useState(false);
    

    return (
        <div className="modal">
        {/* if isOpen is false open the modal and I use two div for make the close clic the modal*/}
            <button className={props.class} onClick={() => setIsOpen(!isOpen)}>{props.button}</button>
            {isOpen ? (
            <div className="addtest">
            <div className="modalV modal01">
                <h3>{props.name}</h3>
                <ModalForm 
                    url={props.url}
                    method={props.method}
                    open={setIsOpen}
                    data={props.data}
                />
            </div>
            <div className='badmodal' onClick={() => setIsOpen(false)}>
            </div>
            </div>
            ) : null}
        </div>
    );
};

export default ModalForms;