import React, { useEffect, useState } from 'react';
import Validation from '../ErrorValidationForm';
import DynamicForm from '../DynamicForm';
import Url from '../../../Url.json'

const ModalForm = (props) => {

    let datafirstname;
    let datalastname;
    let datalocation;
    let dataemail;
    let datapassword;
    let datagroup;
    let dataactive;

    if (props.method === 'POST') {
        datafirstname = '';
        datalastname = '';
        datalocation = '';
        dataemail = '';
        datapassword = '';
        datagroup = 'user';
        dataactive = false;
    } else {
        datafirstname = props.data.firstName;
        datalastname = props.data.lastName;
        datalocation = props.data.location;
        dataemail = props.data.email;
        datapassword = props.data.password;
        datagroup = props.data.group;
        dataactive = props.data.active;
    }
    
    // for the errors
    const [errors, setErrors] = useState({});
    
    // statuts based on errors
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    // Collect all groups and put in select
    const [groups, setGroups] = useState([]);

    // Collect all location and put in select
    const [locations, setLocations] = useState([])

    //Look if the checkbox is checked
    const [checked, setChecked] = useState(dataactive);

    
    // allow change the value and collect the new value
    const [values, setValues] = useState({
        firstname: datafirstname,
        lastname: datalastname,
        location: datalocation,
        email: dataemail,
        password: datapassword,
        group: datagroup,
        active: dataactive
    });


     /**
     * Data for the select
     * 
     * @param {String} url Url of the server
     */
      async function Group(url) {
        try {
            let response = await fetch(url + '/api/groups', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                }
            });
            let data = await response.json();
            data.groups.map((string) => {
                if (string.name === 'admin') {
                    // Class give for admin
                    string.class = '';
                }
                if (string.name === 'user') {
                    // Class give for user
                    string.class = '';
                }
                return null;
            })
            setGroups(data.groups);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Data for the select Location
     * 
     * @param {String} url Url of the server
     */
     async function Location(url) {
        try {
            let response = await fetch(url + '/api/locations');
            let data = await response.json();
            setLocations(data.locations);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        Group(Url.url);
        Location(Url.url);
    }, []);


    /**
     * Catch the name with the value
     * 
     * @param {event} event It's the onChange
     */
    const handleChange = (event) => {
        if (event.target.name === 'active') {
            if (checked === false) {
                setChecked(!checked, event.target.value = true);
            } else {
                setChecked(!checked, event.target.value = false);
            }
        }
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Put the user
     * 
     * @param {string} url Url of the server
     * @param {string} method Method REST you need to use
     * @param {Object} data data of the row
     * @param {Object} value data of the form
     * @param { ?String } validationDate date when the active began true
     */
    async function UserPut(url, method, data, value, validationDate) {
        
        try {
            await fetch(url + data._id, {
                method: method,
                body: JSON.stringify({
                    _id: data._id,
                    lastName: value.lastname,
                    firstName: value.firstname,
                    location: value.location,
                    email: value.email,
                    password: value.password,
                    group: value.group,
                    active: value.active,
                    validationDate: validationDate
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                }
            })
                .then(res => { 
                    if (res.status === 200) {
                        window.location.reload();
                    }
                })

        } catch (err) {
            throw err;
        }
    }

     /**
     * Post the user
     * 
     * @param {string} url Url of the server
     * @param {string} method Method REST you need to use
     * @param {Object} value data of the form
     * @param { ?String } validationDate date when the active began true
     */
    async function UserPost(url, method, value, validationdate) {
        try {
            await fetch(url, {
                method: method,
                body: JSON.stringify({
                    lastName: value.lastname,
                    firstName: value.firstname,
                    location: value.location,
                    email: value.email,
                    password: value.password,
                    group: value.group,
                    active: value.active,
                    validationDate: validationdate
                }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        window.location.reload();
                    }
                })

        } catch (err) {
            throw err;
        }
    }

    /**
     * When the form is finish and ok
     * 
     * @param {event} event It's the OnSubmit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors(Validation(values));
        
        setDataIsCorrect(true);
    }

    /**
     * Allow to send the data
     */
     useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect === true) {
            let date = Date.now();
                //Vérifie if the active for add or don't change the validation date
                if (values.active === 'true') {
                    if (props.method === 'PUT') {
                        if (props.data.active === true) { 
                            UserPut(props.url, props.method, props.data, values, props.data.validationDate);
                        } else {
                            UserPut(props.url, props.method, props.data, values, date);
                        }
                    } else {
                        UserPost(props.url, props.method, values, date);
                    }  
                } else {
                    if(props.method === 'PUT') {
                        if(props.data.validationDate === "") {
                            UserPut(props.url, props.method, props.data, values);
                        } else {
                            UserPut(props.url, props.method, props.data, values, props.data.validationDate);
                        }
                    } else {
                        UserPost(props.url, props.method, values);
                    }
                }
                props.open(false);
        }

    }, [errors, values, props, dataIsCorrect]);

    // Insert all the element you need in the form see exemple in DynamicForm
    const inputs = [
        {
            id: "putuserfirstname", title: "First Name", value: values.firstname,
            name: "firstname", type: "text", classdiv:"forma",
            handlechange: handleChange, error: errors.firstname
        },
        {
            id: "putuserlastname", title: "Last Name", value: values.lastname,
            name: "lastname", type: "text", classdiv:"forma",
            handlechange: handleChange, error: errors.lastname
        },
        {
            id: "putuserlocation", title: "Location", value: values.location,
            name: "location", type: "select",
            classdiv: "forma", classinput: "selectFormModal",
            handlechange: handleChange, error: errors.location,
            data: locations, null: 'Choose your location'
        },
        {
            id: "putuseremail", title: "Email", value: values.email,
            name: "email", type: "email", classdiv:"forma",
            handlechange: handleChange, error: errors.email
        },
        {
            id: "putuserpassword", title: "Password", value: values.password,
            name: "password", type: "text", classdiv:"forma",
            handlechange: handleChange, error: errors.password
        },
        {
            id: "putusergroup", title: "Group", value: values.group, classdiv:"forma formaRadio", classinput: "selectFormModal",
            data: groups, name: "group", type: "select",
            handlechange: handleChange
        },
        {
            id: "putuseractive", title: "Active", value: values.active, 
            name: "active", type: "checkbox", checked: checked, classdiv:"forma formaRadio",
            handlechange: handleChange
        }
    ];

    return (
        <form className="boxForm " onSubmit={handleSubmit}>

            {/* Creation input with DynamicForm */}
            <DynamicForm inputs={inputs} />
            <div className="boxButton">
                <button className="buttonSign" type="submit">OK</button>
                <button className="buttonSign" onClick={() => props.open(false)}>Cancel</button>
            </div>
        </form>
    );
};

export default ModalForm;