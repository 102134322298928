import React, { useEffect, useState } from 'react';

const ModalDeleteAll = (props) => {
    // change the state of the modal
    const [isOpen, setIsOpen] = useState(false);

    // When you click on yes the dataIsCorrect, it began true and start the function UserDelete
    const [dataIsCorrect, setDataIsCorrect] = useState(false)

    /**
     * Delete the the data of the User to the row
     * 
     * @param {string} url url of the site
     * @param {string} method It's the method REST you need to use
     * @param {string} id Id of the row, you need
     */
    function DeleteAll(url, method, id) {
        try {
            fetch(url, {
                method: method,
                body: JSON.stringify({
                    remove: id
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                }
            })
                .then(res => {
                    res.json();
                    if (res.status === 200) {
                        window.location.reload();
                    }
                })
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        if (dataIsCorrect === true) {
            DeleteAll(props.url, props.method, props.data)
            setIsOpen(false);
        }

    }, [dataIsCorrect, props]);

    return (
        <div className="modalValidation filtreDelete">
            {/* if isOpen is false open the modal */}
            <button className={props.class} onClick={() => setIsOpen(!isOpen)}>{props.button}</button>
            {isOpen ? (
                <div className='badmodal' onClick={() => setIsOpen(false)}>
                    <div className="modalV">
                        <h3>{props.name}</h3>
                        <p>Are you sure you want to delete {props.data.length} trash ?</p>
                        <button onClick={() => setIsOpen(false)}>No</button>
                        <button onClick={() => setDataIsCorrect(true)}>Yes</button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ModalDeleteAll;