import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Download from './pages/User/Download';
import Log from './pages/Admin/Log';
import Login from './pages/Login';
import Map from './pages/Admin/Map';
import NotFound from './pages/NotFound';
import Registration from './pages/Registration';
import Trash from './pages/Admin/Trash';
import User from './pages/Admin/User';
import Url from './Url.json';
import UploadApk from './pages/Super_Admin/UploadApk';

const App = () => {

  const pagination = 100;

  return (
    // Creat all way
    <BrowserRouter>
      <Switch>
        <Route path="/" sensitive exact>
        <Login
            url={Url.url}
            />
        </Route>
        <Route path="/register" sensitive exact>
        <Registration
            url={Url.url}
          />
        </Route>
        <Route path="/download" sensitive exact>
        <Download
          url={Url.url}
        />
        </Route>
        <Route path="/admin-user" sensitive exact>
        <User
            url={Url.url}
            paginationLimit={pagination}
          />
        </Route>
        <Route path="/admin-trash" sensitive exact>
        <Trash
            url={Url.url}
            paginationLimit={pagination}
          />
        </Route>
        <Route path="/admin-map" sensitive exact>
        <Map
            url={Url.url}
          />
        </Route>
        <Route path="/admin-log" sensitive exact>
        <Log
            url={Url.url}
            paginationLimit={pagination}
          />
        </Route>
        <Route path="/upload" sensitive exact>
        <UploadApk
            url={Url.url}
          />
        </Route>
        {/* By default if the  way is not exact, it back the NotFound */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;