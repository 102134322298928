import React from 'react';
import { useLocation } from 'react-router-dom';
import ModalDeleteAll from './Modals/ModalDeleteAll';

const FilterTrash = (props) => {
    const location = useLocation();
    return (
        <div className='filterData'>
            <input type="date" name="firstDate" id="" value={props.filtre.firstDate} onChange={props.handleChange} max={props.filtre.secondDate}/>
            <p>&#10141;</p>
            <input type="date" name="secondDate" id="" value={props.filtre.secondDate} onChange={props.handleChange} min={props.filtre.firstDate}/>
            <select name="region" id="" value={props.filtre.region} onChange={props.handleChange}>
                <option value="">-- Region --</option>
                {props.data.map((locations, i) => <option value={locations.name}key={i}>{locations.name}</option>)}
            </select>
            {location.pathname === "/admin-trash" && <ModalDeleteAll
                button={""}
                class={"buttonDeleteAll"}
                name={"Delete Trashes"}
                method={"DELETE"}
                data={props.remove}
                url={props.url + '/api/trashes'}
            />}
        </div>
    );
};

export default FilterTrash;